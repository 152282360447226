<template>
    <v-dialog :value="value" @input="$emit('input', $event.target ? $event.target.value : false)" max-width="600">
        <v-card class="pa-0" max-width="600">
            <v-toolbar short flat color="white">
                <v-toolbar-title class="green--text">Create New Organization</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="px-5">
            <v-form @submit.prevent="onFormSubmit" @keyup.enter.prevent="onFormSubmit">
                <v-text-field
                    ref="organizationNameInput"
                    v-model=newOrganizationName
                    label="Organization Name"
                    :rules="newOrganizationNameRules"
                    validate-on-blur
                    color="green"
                    required
                    hint="The name for the organization"
                    type="text"
                    outlined
                >
                </v-text-field>
            </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn elevation="4" class="green white--text" @click="onFormSubmit" :disabled="!isFormComplete">
                    <span>Create</span>
                </v-btn>
                <v-btn text color="grey" @click="cancel">
                    <span>Cancel</span>
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import { isValidName, compact } from '@/sdk/input';
// import { client } from '@/client';

export default {
    props: {
        value: {
            type: Boolean,
            default: false,
            required: true,
        },
    },

    data: () => ({
        visible: false, // TODO is this really needed? :value @input above maybe all we need
        list: [],
        submitTimestamp: null,
        // create organization
        displayCreateOrganization: false,
        createOrganizationForm: null,
        newOrganizationName: null,
        newOrganizationNameRules: [
            (v) => !!v || 'Organization name is required',
            (v) => !v || isValidName(compact(v)) || 'Organization name is required',
        ],
        // add organization
        addOrganizationChoices: [],
        addOrganizationId: null,
    }),

    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        id() {
            return this.policyId;
        },
        isFormComplete() {
            // TODO: check that organization name is not too similar to existing organization name
            return typeof this.newOrganizationName === 'string' && this.newOrganizationName.trim().length > 0;
        },
    },

    watch: {
        displayCreateOrganization(value) {
            if (value && (this.add || this.create)) {
                this.newOrganizationName = '';
                this.initAddOrganizationChoices();
            }
        },
        value(newValue) {
            console.log(`dialog new value for v-model: ${newValue}`);
            if (newValue) {
                setTimeout(() => { this.$nextTick(() => this.$refs.organizationNameInput.focus()); }, 1);
            }
        },
        // TODO is this 'visible' prop really needed? :value @input above maybe all we need
        visible(value) {
            this.$emit('input', value);
        },
    },

    methods: {
        onFormSubmit() {
            if (!this.isFormComplete) {
                return;
            }
            if (Number.isInteger(this.submitTimestamp) && this.submitTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitTimestamp = Date.now();
            this.createOrganization();
        },
        cancel() {
            this.$emit('cancel');
        },
        async createOrganization() {
            try {
                this.$store.commit('loading', { createOrganization: true });
                console.log('createOrganization');
                const request = {
                    // name: this.name,
                    name: this.newOrganizationName,
                    // agreeToTerms: this.isAgreeToTermsChecked,
                    // interactionId: this.interactionId, // will be present if organization arrives from an existing interaction, such as clicking on a specific link to get started and we can use this to redirect the organization to an appropriate location after organization is created
                };
                const response = await this.$client.user(this.user.id).user.createOrganization(request);
                console.log('createOrganization response: %o', response);
                const { isCreated, id, error } = response;
                if (isCreated) {
                    const organization = { id, ...request };
                    this.list.push(organization);
                    this.$emit('created-organization', organization);
                    this.$emit('added-organization', organization);
                    this.displayCreateOrganization = false;
                    this.newOrganizationName = null;
                } else if (error) {
                    console.error(`request failed: ${JSON.stringify(error)}`);
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create organization' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create organization' });
                }
                this.$emit('created', { /* todo: new organization info */ });
            } catch (err) {
                console.error('failed to create organization', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create organization' });
            } finally {
                this.$store.commit('loading', { createOrganization: false });
            }
        },
    },
};
</script>
